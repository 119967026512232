import React from 'react'
// Types
import { SectionMarquee } from '../../../types/sections'
// Component
import { default as MarqueeElement } from 'react-fast-marquee'
import { Box, Text } from '../../../atoms'
import { SECTION_MARGIN } from '../../../utils/constants'

const Marquee: React.FC<SectionMarquee> = (props) => {
  const { id, sectionId, text, color } = props
  return (
    <Box as={'section'} id={sectionId || id}>
      <MarqueeElement speed={40} gradient={false}>
        <Text
          mr={[4, 5, 6]}
          fontSize={[96, 116, 232]}
          lineHeight={'1em'}
          fontWeight={'ultraBold'}
          color={color?.hex || 'dark'}
        >
          {text}
        </Text>
      </MarqueeElement>
    </Box>
  )
}

export default Marquee
